function Confidentialite(){

return (

    <div>
Politique de Confidentialité de l'Application
Dernière mise à jour : 09/12/2024

Merci d'utiliser notre application. La confidentialité de vos données est très importante pour nous. Cette politique de confidentialité décrit comment nous gérons les informations lorsque vous utilisez notre application. Nous vous assurons que nous ne collectons aucune donnée personnelle de nos utilisateurs.

Informations que nous collectons
Nous ne collectons aucune donnée personnelle identifiable lorsque vous utilisez notre application. Nous ne demandons ni votre nom, adresse e-mail, numéro de téléphone, ni aucune autre donnée personnelle.

Données que nous ne collectons pas
Aucune donnée personnelle : Nous ne collectons ni ne traitons aucune donnée personnelle vous concernant, telles que vos informations d'identification.
Aucune donnée de localisation : Nous n'accédons pas à votre localisation géographique.
Aucun suivi de cookies : Nous n'utilisons pas de cookies pour suivre vos activités en ligne ou collecter des informations.
Partage des données
Nous ne partageons aucune information vous concernant avec des tiers. Nous ne collectons pas d'informations personnelles, donc il n'y a aucune donnée à partager.

Sécurité
Bien que nous ne collectons aucune donnée personnelle, nous prenons la sécurité de votre utilisation de l'application au sérieux. Nous nous efforçons de maintenir un environnement sûr pour vous, mais n'ayant aucune donnée personnelle à protéger, il n'y a pas de risque associé à la sécurité des données.

Mises à jour de cette politique de confidentialité
Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec la date de la mise à jour. Nous vous encourageons à consulter cette page régulièrement pour être informé des dernières informations concernant notre politique de confidentialité.

Contact
Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse suivante :

[Votre adresse e-mail ou formulaire de contact]

Consentement
En utilisant notre application, vous acceptez cette politique de confidentialité. Si vous n'êtes pas d'accord avec cette politique, nous vous encourageons à ne pas utiliser notre application.
</div>
)}

export default Confidentialite